import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import {
  VoucherGroup,
  Order,
  TownshipPublicSettings,
} from 'src/app/interfaces';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-buy-success',
  templateUrl: './buy-success.component.html',
  styleUrls: ['./buy-success.component.scss'],
})
export class BuySuccessComponent implements OnInit {
  env = environment;
  redirectUrl: string;
  order: Order;
  titleAbove: string;
  titleBelow: string;
  tableArticleVoucher: string;
  tableArticleCosts: string;
  tableArticleTotal: string;

  awaitingResponse: boolean = true;
  orderRecheck: boolean = false;

  displayedColumns: string[] = ['Artikel', 'Aantal', 'Prijs'];
  columnsToDisplay: string[] = this.displayedColumns.slice();
  data: any[] = [];

  failed = false;

  constructor(
    private route: ActivatedRoute,
    public db: AngularFirestore,
    private http: HttpClient,
    private translate: TranslateService
  ) {}

  async ngOnInit() {
    this.translate.setDefaultLang(environment.language);
    this.translate.use(environment.language);
    this.route.params.subscribe(async (params) => {
      console.log('params', params);
      let res: Observable<any>;
      const requestUrl = `${this.env.functionsUrl}/httpGetOrder`;
      const postData = {
        voucherGroupdId: params.voucherGroupdId,
        townshipId: params.townshipId,
        orderId: params.orderId,
      };

      res = this.http.post(requestUrl, postData);
      res.subscribe(async (result) => {
        console.log('result', result);
        if (result) {
          this.orderRecheck = true;
          const voucherGroup = result.voucherGroup as VoucherGroup;
          const order = result.order as Order;
          console.log('ORDER', order);

          this.order = order;
          const township = result.township as TownshipPublicSettings;
          if (voucherGroup.redirectUrl) {
            this.redirectUrl = voucherGroup.redirectUrl;
          }
          console.log('order.status', order.status);
          if (order.status === 'done' || order.status === 'paid') {
            this.titleAbove = await this.getTranslation(
              'succeed.message-above'
            );
            this.titleBelow = await this.getTranslation(
              'succeed.message-under'
            );
            this.tableArticleVoucher = await this.getTranslation(
              'succeed.article-voucher'
            );
            this.tableArticleCosts = await this.getTranslation(
              'succeed.article-costs'
            );
            this.tableArticleTotal = await this.getTranslation(
              'succeed.article-total'
            );
            let envName = township.voucherPrefix;
            envName = envName.charAt(0).toUpperCase() + envName.slice(1);
            this.data = [
              {
                Artikel: `${envName} ${this.tableArticleVoucher} €${order.price}`,
                Aantal: `${order.amount}x`,
                Prijs: `	€${this.getCurrencyString(order.price * order.amount)}`,
              },
              {
                Artikel: `${this.tableArticleCosts}`,
                Aantal: `1x`,
                Prijs: `	€${this.getCurrencyString(order.administrationCost)}`,
              },
              {
                Artikel: `${this.tableArticleTotal}`,
                Aantal: ``,
                Prijs: `	€${this.getCurrencyString(order.totalPrice)}`,
              },
            ];
          } else if (
            order.status === 'canceled' ||
            order.status === 'failed' ||
            order.status === 'expired'
          ) {
            this.titleAbove = await this.getTranslation('failed.message-above');
            this.titleBelow = await this.getTranslation('failed.message-under');
            this.failed = true;
          } else if (order.status === 'open') {
            if (voucherGroup.paymentProvider === 'ideal') {
              this.titleAbove = await this.getTranslation(
                'processing.message-above-ideal'
              );
              this.titleBelow = await this.getTranslation(
                'processing.message-under-ideal'
              );
            } else {
              this.titleAbove = await this.getTranslation(
                'processing.message-above'
              );
              this.titleBelow = await this.getTranslation(
                'processing.message-under'
              );
            }
            this.failed = true;
          } else {
            this.titleAbove = await this.getTranslation('failed.message-above');
            this.titleBelow = await this.getTranslation('failed.message-under');
            this.failed = true;
          }
          this.awaitingResponse = false;
          if (this.order.type === 'digital' && !this.order.downloadUrl) {
            this.waitAndRecheckOrder(params);
          } else {
            this.orderRecheck = false;
          }
        }
      });
    });
  }

  openWindow(url: string) {
    window.open(url, '_blank');
  }

  getTranslation(objName): any {
    const res = this.translate.instant(`buy.${objName}`);
    return res;
  }

  getCurrencyString(number: any) {
    if (!number) {
      number = 0;
    }
    let newNumber;
    if (!number.toString().includes('.')) {
      // this isnt a decimal
      newNumber = `${number.toString()},-`;
    } else {
      newNumber = `${number.toFixed(2)}`;
      newNumber = newNumber.replace('.', ',');
    }
    return newNumber;
  }

  waitAndRecheckOrder(params) {
    setTimeout(() => {
      let res: Observable<any>;
      const requestUrl = `${this.env.functionsUrl}/httpGetOrder`;
      const postData = {
        voucherGroupdId: params.voucherGroupdId,
        townshipId: params.townshipId,
        orderId: params.orderId,
      };
      res = this.http.post(requestUrl, postData);

      res.subscribe(async (result) => {
        if (result) {
          this.order = result.order as Order;
        }
      });
      this.orderRecheck = false;
    }, 10000);
  }
}
