// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
  font-family: Viga;
  src: url('Viga-Regular.ttf');
}
::ng-deep .mat-mdc-form-field {
  --mat-form-field-container-text-line-height: 26px;
}`, "",{"version":3,"sources":["webpack://./src/app/app.component.scss"],"names":[],"mappings":"AAAA;EACE,iBAAA;EACA,4BAAA;AACF;AAEA;EACE,iDAAA;AAAF","sourcesContent":["@font-face {\n  font-family: Viga;\n  src: url('../assets/fonts/Viga/Viga-Regular.ttf');\n}\n\n::ng-deep .mat-mdc-form-field {\n  --mat-form-field-container-text-line-height: 26px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
