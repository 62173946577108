import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { collection, doc, getDoc, getDocs } from 'firebase/firestore';
import {
  calculateTextColor,
  fixTermsUrl,
  getCurrencyString,
} from 'src/app/globals';
import {
  GroupLink,
  HouseInfo,
  TownshipPublicSettings,
  VoucherGroup,
} from 'src/app/interfaces';
import { CustomValidators } from 'src/app/validators/custom-validators';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';
import { MatDialog } from '@angular/material/dialog';
// import { VerifyPinComponent } from 'src/app/find-voucher-groups/dialogs/verify-pin/verify-pin.component';
// import { VerifySmsComponent } from 'src/app/find-voucher-groups/dialogs/verify-sms/verify-sms.component';
import { AmbitionLevels, MeasuresList } from 'src/app/enums';
import { ActivateVoucherComponentData } from 'src/app/activate-voucher/activate-voucher.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { httpsCallable } from 'firebase/functions';
import { db, functions } from 'src/app/app.component';

@Component({
  selector: 'app-create-passport',
  templateUrl: './create-passport.component.html',
  styleUrls: ['./create-passport.component.scss'],
})
export class CreatePassportComponent implements OnInit {
  townshipId: string;
  township: TownshipPublicSettings;
  termsUrl?: string;
  highContrast: boolean = false;
  houseInfo: HouseInfo;

  step: number = 0;

  awaitingResponse: boolean;
  errorMessage: string;
  voucherGroups: VoucherGroup[];
  groupLinkedVoucherGroups: VoucherGroup[];
  voucherGroupGroupLinks: any[];
  filteredVoucherGroups: VoucherGroup[] = [];
  groupLinksFullInfo: any[];
  groupLinks: GroupLink[];
  noActions: boolean = false;
  getCurrencyString = getCurrencyString;
  activateVoucherComponentData: ActivateVoucherComponentData;

  // Step 0
  addressForm: FormGroup = new FormGroup({
    postal: new FormControl('', [CustomValidators.postalValidator]),
    houseNumber: new FormControl('', [
      CustomValidators.numberInput(false, false, 0),
    ]),
    houseNumberAddition: new FormControl(''),
  });

  // Step 1
  houseTypeForm: FormGroup = new FormGroup({
    houseType: new FormControl(null, [Validators.required]),
  });

  // Step 2
  sustainabilityOptionsForm: FormGroup = new FormGroup({
    measures: new FormControl([]),
  });
  measuresList: string[] = Object.values(MeasuresList).map(function (level) {
    return level;
  });

  // Step 3
  goalForm: FormGroup = new FormGroup({
    goal: new FormControl(null, [Validators.required]),
  });

  // Step 4
  verificationForm: FormGroup = new FormGroup({});

  // Step 5
  voucherGroupForm: FormGroup = new FormGroup({
    voucherGroup: new FormControl(null, Validators.required),
  });

  // Step 6
  termsForm: FormGroup = new FormGroup({
    acceptTerms: new FormControl(null, Validators.requiredTrue),
  });

  theme = {
    primary: { background: '#006239', text: '#FFFFFF' },
    accent: { background: '#913a00', text: '#FFFFFF' },
    image: '',
  };

  constructor(
    private route: ActivatedRoute,
    private translate: TranslateService,
    public dialog: MatDialog,
    private snackBar: MatSnackBar
  ) {}

  async ngOnInit() {
    if (
      localStorage.getItem('highContrast') &&
      localStorage.getItem('highContrast') === '1'
    ) {
      this.highContrast = true;
    }

    this.translate.setDefaultLang(environment.language);
    this.translate.use(environment.language);

    this.route.params.subscribe(async (params) => {
      console.log('params', params);
      const townshipName = params.townshipName;
      await this.getTownshipInfo(townshipName);
    });
  }

  toggleMeasure(measure: string) {
    let measures = this.sustainabilityOptionsForm.value.measures as string[];
    if (measures.includes(measure)) {
      measures.splice(measures.indexOf(measure), 1);
      this.measuresList.push(measure);
    } else {
      measures.push(measure);
      this.measuresList.splice(this.measuresList.indexOf(measure), 1);
    }
    this.sustainabilityOptionsForm.controls.measures.setValue(measures);
  }

  async getTownshipInfo(townshipName: string) {
    const townshipNameDoc = (
      await getDoc(doc(db, `townshipNames/${townshipName}`))
    ).data();
    console.log('townshipNameDoc', townshipNameDoc);
    if (!townshipNameDoc.townshipId) {
      return;
    }
    this.townshipId = townshipNameDoc.townshipId;
    this.township = (
      await getDoc(doc(db, `township/${this.townshipId}/settings/public`))
    ).data() as TownshipPublicSettings;
    console.log('township', this.township);
    if (this.township.primaryColor) {
      this.theme.primary = {
        background: this.township.primaryColor,
        text: calculateTextColor(this.township.primaryColor),
      };
    }

    if (this.township.accentColor) {
      this.theme.accent = {
        background: this.township.accentColor,
        text: calculateTextColor(this.township.accentColor),
      };
    }

    if (this.township.logoImageUrl) {
      this.theme.image = this.township.logoImageUrl;
    } else {
      switch (this.township.voucherPrefix) {
        case 'lokale':
          this.theme.image = '../../assets/images/lokalebon.png';
          break;
        case 'toegangs':
          this.theme.image = '../../assets/images/toegangsbon.png';
          break;
        case 'duurzaamwonen':
          this.theme.image = '../../assets/images/duurzaamwonen.png';
          break;
        default:
          this.theme.image = '../../assets/images/groenebon.png';
          break;
      }
    }
    this.termsUrl = fixTermsUrl(this.township.termsUrl);
    if (this.termsUrl) {
      const termsLowerCase = this.termsUrl.toLocaleLowerCase();
      if (
        termsLowerCase.includes('groeneapp') ||
        termsLowerCase.includes('groenebon')
      ) {
        this.termsUrl = null;
      }
    }
  }

  async getHouseInfo() {
    if (!this.addressForm.valid || this.awaitingResponse) {
      return this.addressForm.markAllAsTouched();
    }
    const form = this.addressForm.value;
    console.log('form', form);
    this.awaitingResponse = true;
    const postal = (form.postal as string).toUpperCase().replace(/\s+/g, '');
    console.log('postal', postal);
    const houseNumber = form.houseNumber.toString();
    const houseNumberAddition = form.houseNumberAddition
      ? (form.houseNumberAddition as string)
      : null;
    const callable = httpsCallable(functions, 'httpSearchVoucherGroups');
    console.log('calling now');

    const result: any = await callable({
      townshipId: this.townshipId,
      postal: postal,
      houseNumber,
      houseNumberAddition,
      skipChecks: true,
    });

    console.log('result', result);
    if (result.data.status === 'address_not_found_in_system') {
      this.snackBar.open('Uw adres is niet bekend in het systeem.', 'X', {
        duration: 5000,
      });
    }
    if (result.data.status === 'address_found_in_system') {
      await this.getAddressFromApi();
    }
    this.awaitingResponse = false;
  }

  async getAddressFromApi() {
    const houseNumberAddition = this.addressForm.value.houseNumberAddition
      ? (this.addressForm.value.houseNumberAddition as string)
      : null;
    const callable = httpsCallable(functions, 'httpGetAddressFromKadasterApi');
    const result: any = await callable({
      postal: this.addressForm.value.postal,
      houseNumber: this.addressForm.value.houseNumber,
      houseNumberAddition: houseNumberAddition,
      townshipId: this.townshipId,
    });
    console.log('RESULT:', result.data);
    if (result.data.status) {
      if (result.data.status === 404 || result.data.status === 'NOT_FOUND') {
        this.snackBar.open(
          'Geen adres gevonden met deze postcode en huisnummer.',
          'X',
          {
            duration: 5000,
          }
        );
        return (this.awaitingResponse = false);
      } else {
        this.snackBar.open(
          'Er is iets mis gegaan met het ophalen van het adres met deze postcode en huisnummer.',
          'X',
          {
            duration: 5000,
          }
        );
        return (this.awaitingResponse = false);
      }
    } else {
      this.houseInfo = {
        postal: this.addressForm.value.postal,
        houseNumber: this.addressForm.value.houseNumber,
        houseNumberAddition: this.addressForm.value.houseNumberAddition ?? null,
        buildYear: result.data.building.buildYear,
        surfaceM2: result.data.building.surfaceM2,
        energyLabel: result.data.building.energyLabel,
      };
      this.step++;
    }
  }

  async findVoucherGroups(verificationForm?: any) {
    if (!this.addressForm.valid || this.awaitingResponse) {
      return;
    }
    const form = this.addressForm.value;
    console.log('form', form);
    this.awaitingResponse = true;
    const postal = (form.postal as string).toUpperCase().replace(/\s+/g, '');
    console.log('postal', postal);
    const houseNumber = form.houseNumber.toString();
    const houseNumberAddition = form.houseNumberAddition
      ? (form.houseNumberAddition as string)
      : null;
    const callable = httpsCallable(functions, 'httpSearchVoucherGroups');
    console.log('calling now');

    const result: any = await callable({
      townshipId: this.townshipId,
      postal: postal,
      houseNumber,
      houseNumberAddition,
      pin: verificationForm?.pin,
      phonenumber: verificationForm?.phone,
      verificationCode: verificationForm?.smsCode,
      measures: this.measuresList,
    });
    console.log('result', result);
    this.awaitingResponse = false;
    if (result.data?.status === 'pin') {
      if (this.verificationForm.controls)
        this.verificationForm = new FormGroup({
          pin: new FormControl(null, Validators.required),
        });
      this.step = 4;
    } else if (result.data.status === 'address_not_found_in_system') {
      return (this.errorMessage = 'find-voucher-group.error_address_not_found');
    } else if (
      result.data.status === 'smsVerificationRequired' ||
      result.data.status === 'verification_code_sent'
    ) {
      this.verificationForm = new FormGroup({
        phone: new FormControl(null, Validators.required),
        smsCode: new FormControl(null, Validators.required),
      });
      this.step = 4;
    } else if (result.data.status === 'phonenumber_does_not_match') {
      return (this.errorMessage =
        'find-voucher-group.error_matching_phonenumber');
    } else if (
      result.data.status === 'phonenumber_already_coupled_to_other_address'
    ) {
      return (this.errorMessage =
        'find-voucher-group.error_coupled_phonenumber');
    }
    this.groupLinksFullInfo = []; // all grouplinks in a township
    const allGroupLinks = await getDocs(
      collection(db, `township/${this.townshipId}/groupLinks`)
    );
    allGroupLinks.forEach((groupLinkInfoDoc) => {
      const groupLinkInfo = groupLinkInfoDoc.data();
      if (!groupLinkInfo.order) groupLinkInfo.order = 1;
      this.groupLinksFullInfo.push(groupLinkInfo);
    });
    this.groupLinksFullInfo.push({
      name: 'none',
      text: 'Geen',
      order: 10000,
    });
    if (result.data.voucherGroups) {
      console.log('result.data.voucherGroups', result.data.voucherGroups);
      this.groupLinkedVoucherGroups = []; //vouchergroups with a grouplink
      this.groupLinks = []; // all grouplinks(the objects) in the result of vouchergroups
      this.voucherGroupGroupLinks = []; // all grouplinks(only names) in the result of vouchergroups
      result.data.voucherGroups.forEach((resultVoucherGroup) => {
        if (!resultVoucherGroup.groupLink) {
          resultVoucherGroup.groupLink = 'none';
        }
        this.groupLinkedVoucherGroups.push(resultVoucherGroup);
        if (
          !this.voucherGroupGroupLinks.includes(resultVoucherGroup.groupLink)
        ) {
          this.groupLinksFullInfo.forEach((groupLinksSelectedInfo) => {
            if (groupLinksSelectedInfo.name === resultVoucherGroup.groupLink) {
              this.groupLinks.push(groupLinksSelectedInfo);
            }
          });
          this.voucherGroupGroupLinks.push(resultVoucherGroup.groupLink);
        }
      });

      this.checkSituation();
      this.sortingOrder();

      this.voucherGroups = this.filteredVoucherGroups;
      this.noActions = this.voucherGroups.length < 1 ? true : false;

      this.errorMessage = undefined;
      if (this.voucherGroups.length > 0) {
        let indexToSelect = 0;
        if (this.groupLinks.length > 0) {
          indexToSelect = this.voucherGroups.findIndex(
            (object) => object.groupLink === this.groupLinks[0].name
          );
        }
        this.voucherGroupForm.controls['voucherGroup'].setValue(
          this.voucherGroups[indexToSelect]
        );
      }
      this.step = 5;
    } else if (result.error === 'errorAddress') {
      console.log('result', result);
      this.errorMessage = 'find-voucher-group.error_searching_address';
    } else {
      this.errorMessage = 'find-voucher-group.generic_error';
    }
  }

  checkSituation() {
    this.filteredVoucherGroups = [];

    let ambitionLevels = Object.keys(AmbitionLevels).map(function (level) {
      return level;
    });
    ambitionLevels = ambitionLevels.splice(
      0,
      ambitionLevels.indexOf(this.goalForm.controls.goal.value) + 1
    );

    this.groupLinks.forEach((groupLink) => {
      this.groupLinkedVoucherGroups.forEach((voucherGroup) => {
        if (groupLink.name === voucherGroup.groupLink) {
          this.measuresList.forEach((measure) => {
            if (voucherGroup.addressType?.includes(measure)) {
              ambitionLevels.forEach((ambitionLevel) => {
                if (voucherGroup.ambitionLevel?.includes(ambitionLevel)) {
                  if (!this.filteredVoucherGroups.includes(voucherGroup)) {
                    this.filteredVoucherGroups.push(voucherGroup);
                  }
                }
              });
              if (!voucherGroup.ambitionLevel) {
                if (!this.filteredVoucherGroups.includes(voucherGroup)) {
                  this.filteredVoucherGroups.push(voucherGroup);
                }
              }
            }
          });
        }
      });
    });

    let newGroupLinks = [];
    this.groupLinks.forEach((groupLink) => {
      let voucherGroupsInGroupLink = [];
      console.log('grouplink', groupLink.name);
      this.filteredVoucherGroups.forEach((voucherGroup) => {
        if (voucherGroup.groupLink === groupLink.name) {
          voucherGroupsInGroupLink.push(voucherGroup);
        }
      });

      let obj = {
        'name': `${groupLink.name}`,
        'text': groupLink.text,
        'order': groupLink.order,
        'voucherGroups': voucherGroupsInGroupLink,
      };
      if (voucherGroupsInGroupLink.length > 0) {
        newGroupLinks.push(obj);
      }
    });

    console.log('newGroupLinks', newGroupLinks);
    this.groupLinks = newGroupLinks;
  }

  sortingOrder() {
    console.log(this.groupLinks);
    this.groupLinks.sort((groupLink1, groupLink2) => {
      if (groupLink1.order > groupLink2.order) {
        return 1;
      }
      if (groupLink1.order < groupLink2.order) {
        return -1;
      }

      if (groupLink1.name > groupLink2.name) {
        return 1;
      }
      if (groupLink1.name < groupLink2.name) {
        return -1;
      }
      return 0;
    });
  }

  async generateActivateVoucherStep() {
    console.log('valid?', this.termsForm.valid);
    if (!this.termsForm.valid) {
      this.termsForm.markAllAsTouched();
      return;
    }
    const addressForm = this.addressForm.value;
    const voucherGroupForm = this.voucherGroupForm.value;

    this.activateVoucherComponentData = {
      townshipId: this.townshipId,
      voucherGroupId: voucherGroupForm.voucherGroup.id,
      colorData: this.theme,
      ambitionLevel: this.goalForm.controls.goal.value,
      userData: addressForm as any,
      width: '590px',
      termsAccepted: true,
      measures: this.measuresList,
      noStatistics: true,
      activateType: 'passport',
    };
    this.step++;
  }

  next(formThatShouldBeValid: FormGroup) {
    if (!formThatShouldBeValid.valid) {
      return formThatShouldBeValid.markAllAsTouched();
    }
    this.step++;
  }

  getError(name) {
    const field = this.addressForm.get(name);
    if (field.touched || !field.pristine) {
      let error;
      if (field.hasError('required')) {
        error = 'required';
      }
      if (field.hasError('startsWith')) {
        error = 'muststartwith06';
      }
      if (field.hasError('number')) {
        error = 'number';
      }
      if (field.hasError('email')) {
        error = 'email';
      }
      if (field.hasError('pattern')) {
        error = 'pattern';
      }
      if (field.hasError('minlength')) {
        error = 'phone';
      }
      if (field.hasError('ibanValidator')) {
        error = 'invalid';
      }
      const res = this.translate.instant(`errors.${error}`);
      return res as string;
    }
    return '';
  }

  setHighContrast() {
    this.highContrast = this.highContrast ? false : true;
    localStorage.setItem('highContrast', this.highContrast ? '1' : '0');
  }
}
