import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { TownshipPublicSettings } from '../interfaces';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';
import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { doc, getDoc } from 'firebase/firestore';
import { getHeaderImageUrl, getEnvName, hexToRgb } from '../globals';

@Component({
  selector: 'app-saldo-checker',
  templateUrl: './saldo-checker.component.html',
  styleUrls: ['./saldo-checker.component.scss'],
})
export class SaldoCheckerComponent implements OnInit {
  env = environment;
  awaitingResponse: boolean;
  townshipId: string;
  township: TownshipPublicSettings;
  saldoCheckerForm: UntypedFormGroup;

  environmentObj: any;
  error: string;
  recaptcha: boolean;

  recaptchaKey: string;
  headerImg: string;

  resultString: string;
  resultAmount?: number;
  resultDate: string;

  originalValue: string;
  value: string;

  colors = {
    primary: '#1a697f',
    primaryBackground: '#efefef',
    accent: '#fc9f5b',
  };

  constructor(
    private route: ActivatedRoute,
    public db: AngularFirestore,
    private fb: UntypedFormBuilder,
    private http: HttpClient,
    private snackBar: MatSnackBar,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.saldoCheckerForm = this.fb.group({
      voucher: [, Validators.required],
    });
    this.translate.setDefaultLang(environment.language);
    this.translate.use(environment.language);
    this.recaptcha = false;
    this.recaptchaKey = this.env.recaptchaPublicKey;
    this.route.params.subscribe(async (params) => {
      this.townshipId = params.townshipId;
      this.township = (
        await getDoc(
          doc(this.db.firestore, `township/${this.townshipId}/settings/public`)
        )
      ).data() as TownshipPublicSettings;
      console.log(this.township);

      if (this.township.logoImageUrl) {
        this.headerImg = this.township.logoImageUrl;
      } else {
        this.headerImg = getHeaderImageUrl(this.township.voucherPrefix);
      }
      this.environmentObj = getEnvName(this.township.voucherPrefix);
      if (this.township.primaryColor) {
        this.colors.primary = this.township.primaryColor;
        this.colors.primaryBackground = hexToRgb(
          this.township.primaryColor,
          0.15
        ).rgbString;
      }
      if (this.township.accentColor) {
        this.colors.accent = this.township.accentColor;
      }
    });
  }

  getError(name) {
    const field = this.saldoCheckerForm.get(name);
    if (field.touched || !field.pristine) {
      let error;

      if (field.hasError('required')) {
        error = 'required';
      }
      if (field.hasError('email')) {
        error = 'email';
      }
      if (field.hasError('pattern')) {
        error = 'pattern';
      }
      if (field.hasError('minlength')) {
        error = 'phone';
      }
      if (field.hasError('unknown_voucher')) {
        error = 'unknown_voucher';
      }
      const res = this.translate.get(`errors.${error}`).toPromise() as any;
      // console.log('res', res.__zone_symbol__value);
      return res.__zone_symbol__value as string;
    }
  }

  async reset() {
    this.value = null;
    this.originalValue = null;
    this.saldoCheckerForm.controls.voucher.patchValue('');
  }

  async checkSaldo() {
    this.resultDate = null;
    this.resultString = null;
    this.awaitingResponse = true;
    if (this.saldoCheckerForm.valid) {
      const voucherNumber = this.saldoCheckerForm.value.voucher;
      if (!voucherNumber) {
        this.snackBar.open('Lorum ipsum', 'X', {
          duration: 5000,
        }); // add translation
      }

      const requestUrl = `${this.env.functionsUrl}/httpCheckSaldo`;
      let res: Observable<any>;
      res = this.http.post(requestUrl, {
        voucherNumber,
        townshipId: this.townshipId,
      });
      res.subscribe((result) => {
        console.log('result', result);
        switch (result.type) {
          case 'reserved':
            this.originalValue = result.originalValue;
            this.value = result.value;
            // this.resultString = this.getTranslation(result.type, [
            //   result.voucherNumber,
            //   result.value,
            // ]);
            break;
          case 'claimed':
            // this.resultString = this.getTranslation(result.type, [
            //   result.voucherNumber,
            //   result.value,
            // ]);
            this.originalValue = result.originalValue;
            this.value = '0';
            console.log('result.validUntilDate', result.validUntilDate);
            if (result.validUntilDate !== 'onbekend') {
              this.resultDate = this.getTranslation('validUntilDate', [
                result.validUntilDate,
              ]);
            } else {
              this.resultDate = this.getTranslation('noUntilValidDate');
            }
            break;
          case 'expired':
            this.resultString = this.getTranslation('expired', [
              result.voucherNumber,
            ]);
            break;
          case 'activated':
            // this.resultString = this.getTranslation(result.type, [
            //   result.voucherNumber,
            //   result.value,
            // ]);
            this.originalValue = result.originalValue
              ? result.originalValue
              : result.value;
            this.value = result.value;
            console.log('result.validUntilDate', result.validUntilDate);
            if (result.validUntilDate !== 'onbekend') {
              this.resultDate = this.getTranslation('validUntilDate', [
                result.validUntilDate,
              ]);
            } else {
              this.resultDate = this.getTranslation('noUntilValidDate');
            }
            break;
          case 'unknown_voucher':
            // this.resultString = this.getTranslation(result.type);
            this.saldoCheckerForm.controls.voucher.setErrors({
              unknown_voucher: true,
            });
            break;
          case 'not_used':
            this.resultString = this.getTranslation(result.type);
            break;
        }
        this.value = Number.parseFloat(this.value).toFixed(2);
        this.originalValue = Number.parseFloat(this.originalValue).toFixed(2);
        this.awaitingResponse = false;
        console.log('resultString', this.resultString);
      });
    }
  }

  resolved(captchaResponse: string) {
    // console.log(`Resolved captcha with response: ${captchaResponse}`);
    if (captchaResponse) {
      this.recaptcha = true;
    } else {
      this.recaptcha = false;
    }
  }

  getTranslation(objName, values?): string {
    let newObj = {};
    if (values) {
      if (values.length === 2) {
        newObj = {
          value: values[0],
          amount: values[1],
        };
      } else if (values.length === 1) {
        newObj = {
          value: values[0],
        };
      }
    }
    console.log('newObj', newObj);
    const message = this.translate.instant(
      `saldo-checker.messages.${objName}`,
      newObj
    ) as string;
    return message;
  }
}
