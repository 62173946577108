import { Component, OnInit } from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-pre-find-voucher-group',
  templateUrl: './pre-find-voucher-group.component.html',
  styleUrls: ['./pre-find-voucher-group.component.scss'],
})
export class PreFindVoucherGroupComponent implements OnInit {
  searchVoucherGroupForm = new UntypedFormGroup({
    postal: new UntypedFormControl('', Validators.required),
    houseNumber: new UntypedFormControl('', Validators.required),
    houseNumberAddition: new UntypedFormControl(''),
  });
  townshipId?: string;

  constructor(
    private route: ActivatedRoute,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.translate.setDefaultLang(environment.language);
    this.translate.use(environment.language);

    this.route.params.subscribe(async (params) => {
      this.townshipId = params.townshipId;
    });
  }

  submit() {
    if (this.searchVoucherGroupForm.valid) {
      const form = this.searchVoucherGroupForm.value;
      form.postal = form.postal.toUpperCase().replace(/\s+/g, '');
      let routerLink = `find-voucher-group/${form.postal}*${form.houseNumber}`;
      if (form.houseNumberAddition) {
        routerLink = `${routerLink}*${form.houseNumberAddition}`;
      }
      if (this.townshipId) {
        routerLink = `${routerLink}/${this.townshipId}`;
      }
      const url = environment.iframeUrl + routerLink;
      window.location.assign(url);
    }
  }

  getError(name) {
    const field = this.searchVoucherGroupForm.get(name);
    if (field.touched || !field.pristine) {
      let error;
      if (field.hasError('required')) {
        error = 'required';
      }
      if (field.hasError('startsWith')) {
        error = 'muststartwith06';
      }
      if (field.hasError('email')) {
        error = 'email';
      }
      if (field.hasError('pattern')) {
        error = 'pattern';
      }
      if (field.hasError('minlength')) {
        error = 'phone';
      }
      const res = this.translate.instant(`errors.${error}`) as string;
      return res;
    }
    return '';
  }
}
